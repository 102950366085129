import useQuery from 'hooks/use-query'
import { getProducts } from './queries.graphql'
import bugsnagClient from 'clients/bugsnag'

const useProducts = ({
  version = undefined,
  coachId = null,
  excludeLiveVideo = false,
  excludeMultiMonth = false,
  priceVersions = undefined,
} = {}) => {
  const productsPayload = useQuery(getProducts, {
    variables: {
      version,
      coachId,
      excludeLiveVideo,
      excludeMultiMonth,
      priceVersions,
    },
  })

  if (productsPayload.error) {
    console.error(productsPayload.error)
    bugsnagClient.notify(productsPayload.error)
  }

  return productsPayload
}

export default useProducts
